document.addEventListener('DOMContentLoaded', function () {
  init();
});

const wrapLastWord = (sentence) => {
  const trimmedSentence = sentence.trim();
  const lastSpaceIndex = trimmedSentence.lastIndexOf(' ');

  if (lastSpaceIndex === -1) {
      return `<span class="text-secondary">${trimmedSentence}</span>`;
  }

  const beforeLastWord = trimmedSentence.slice(0, lastSpaceIndex);
  const lastWord = trimmedSentence.slice(lastSpaceIndex + 1);

  return `${beforeLastWord} <span class="text-secondary">${lastWord}</span>`;
}

const announcementBarScroll = () => {
    let lastScrollTop = 0;
    const announcementBar = document.querySelector('.announcement-bar');
    const headerMain = document.querySelector('.header-main');

    if (!announcementBar || !headerMain) {
      return;
  }
    window.addEventListener('scroll', function () {
        let scrollTop = window.scrollY || document.documentElement.scrollTop;
        if (scrollTop > lastScrollTop) {
            var positionInfo = announcementBar.getBoundingClientRect();
            var height = positionInfo.height;
            announcementBar.classList.add('transform-hidden');
            headerMain.style.marginTop = `-${height}px`;

        } else {
            announcementBar.classList.remove('transform-hidden');
            headerMain.style.marginTop = '0px';

        }
        lastScrollTop = scrollTop;
    });
}

const init = () => {
  announcementBarScroll();

  const h4Element = document.getElementById('heroTitle');
  if (!h4Element) {
      return;
  }
  const originalText = h4Element.textContent || h4Element.innerText;
  h4Element.innerHTML = wrapLastWord(originalText);
}
