// import '../css/app.scss';

import '@fortawesome/fontawesome-free/js/all';
import 'tobii/dist/css/tobii.min.css';
import 'animate.css';
// import 'tiny-slider/dist/tiny-slider.css';
import './misc/functions';

import WOW from 'wow.js';
window.WOW = WOW;

import Tobii from 'tobii';
window.Tobii = Tobii;

// import { tns } from 'tiny-slider/src/tiny-slider';
// window.tns = tns;


console.log("Vite is running");
